var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isActive && _vm.hasShare
        ? _c("context-menu", {
            attrs: {
              icon: _vm.getIconClass,
              section: _vm.section,
              "attach-to-bottom": !_vm.hasOnlyShare,
              "attach-to-right": !_vm.hasOnlyShare,
              "is-assignment-card": _vm.isAssignmentCard,
              "use-redesign": _vm.useRedesign,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (slotScope) {
                    return [
                      _vm.hasResultLink
                        ? _c(
                            "div",
                            { class: _vm.getContentMenuSectionClasses },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "context-menu__button",
                                  attrs: { href: _vm.resultUrl },
                                },
                                [
                                  _vm.useRedesign
                                    ? _c("inline-icon", {
                                        attrs: { icon: "results" },
                                      })
                                    : _c("i", {
                                        staticClass: "icon-tm-analytics",
                                      }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("generic.term.results")) +
                                      "\n        "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasProgress
                        ? _c(
                            "div",
                            { class: _vm.getContentMenuSectionClasses },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "context-menu__button",
                                  on: { click: _vm.showProgressModal },
                                },
                                [
                                  _vm.useRedesign
                                    ? _c("inline-icon", {
                                        attrs: { icon: "results" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "icon icon-horizontal-bar-chart",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.progress.button.title"
                                        )
                                      ) +
                                      "\n        "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasShare
                        ? _c("share-with-buttons", {
                            class: _vm.shareWithButtonClasses,
                            attrs: {
                              "share-header": _vm.shareHeader,
                              "share-buttons": _vm.shareButtons,
                              "share-link": _vm.shareLink,
                              "share-description": _vm.shareDescription,
                              "share-title": _vm.shareTitle,
                              "som-today-environment": _vm.somTodayEnvironment,
                              "use-redesign": _vm.useRedesign,
                            },
                            on: {
                              close: slotScope.close,
                              clickIcon: slotScope.close,
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              3242978913
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.useRedesign && !_vm.hasShare && _vm.hasResultLink
        ? _c(
            "a-tooltip",
            { attrs: { variant: "secondary" } },
            [
              _c("a-icon-button", {
                attrs: {
                  href: _vm.resultUrl,
                  icon: "results",
                  "icon-size": "small",
                },
              }),
              _vm._v(" "),
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(_vm._s(_vm.$t("generic.term.results"))),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }