<template>
    <div class="share-menu">
      <div :class="shareMenuTitleClasses">
        <inline-icon v-if="useRedesign" icon="share"></inline-icon>
        <i v-else class="icon icon-share share-menu__title-icon" @click.prevent="clickIcon"></i>
        <span :class="shareHeaderClass" v-html="shareHeader"></span>
      </div>
      <div :class="shareMenuButtonClasses">
        <div v-if="msTeamsEnabled" :class="msTeamsShareButtonClasses">
          <div
            :class="shareMenuButtonIconClasses"
            :data-href="shareLink"
            :data-msg-text="shareDescription"
            :data-assign-title="shareTitle"
            ref="msteams"
            @click="close"
          ></div>
        </div>
        <div v-if="googleClassroomEnabled" :class="googleClassRoomButtonClasses">
          <div :class="shareMenuButtonIconClasses" ref="googleclassroom"></div>
        </div>
        <div v-if="somTodayEnabled" :class="somTodayWrapperClasses">
          <a @click="shareSomToday" :class="somTodayButtonClasses"></a>
        </div>
      </div>
    </div>
</template>

<script>
  import {mixin as clickaway} from 'vue-clickaway';
  import scriptjs from 'scriptjs'
  import InlineIcon from '../../redesign/InlineIcon'

  export default {
    mixins: [clickaway],

    components: {
      InlineIcon
    },

    data() {
      return {
        msTeamsEnabled: -1 !== this.shareButtons.indexOf('ms-teams'),
        googleClassroomEnabled: -1 !== this.shareButtons.indexOf('google-classroom'),
        somTodayEnabled: -1 !== this.shareButtons.indexOf('som-today')
      }
    },

    props: {
      shareLink: String,
      shareButtons: Array,
      shareHeader: String,
      shareTitle: String,
      shareHeaderClass: {
        type: String,
        default: 'h6'
      },
      shareDescription: String,
      somTodayEnvironment: String,
      useRedesign: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      shareMenuTitleClasses() {
        if(this.useRedesign) {
          return 'whitespace-nowrap mb-2'
        }

        return 'share-menu__title'
      },

      shareMenuButtonClasses() {
        if(this.useRedesign) {
          return 'relative flex mb-1 mt-1'
        }

        return 'share-menu__buttons'
      },

      shareMenuButtonIconClasses() {
        if(this.useRedesign) {
          return 'inline-block w-8 h-8'
        }

        return 'share-menu__button-icon'
      },
      msTeamsShareButtonClasses() {
        if(this.useRedesign) {
          return 'inline-block h-8 w-8'
        }

        return 'share-menu__button share-menu__button--ms-teams';
      },
      googleClassRoomButtonClasses() {
        if(this.useRedesign) {
          return 'inline-block h-8 w-8 ml-2'
        }
        return 'share-menu__button share-menu__button--google-classroom';
      },
      somTodayWrapperClasses() {
        if(this.useRedesign) {
          return 'inline-block h-8 w-8 ml-2'
        }

        return 'share-menu__button share-menu__button--somtoday';
      },

      somTodayButtonClasses() {
        if(this.useRedesign) {
          return 'icon-somtoday cursor-pointer inline-block h-8 w-8';
        }

        return 'share-menu__button-icon share-menu__button-icon-somtoday';
      },

      classes() {
        return '' + this.section
      }
    },

    mounted() {
      const scripts = [];

      if (this.msTeamsEnabled) {
        scripts.push('https://teams.microsoft.com/share/launcher.js')
      }
      if (this.googleClassroomEnabled) {
        scripts.push('https://apis.google.com/js/platform.js')
      }
      if (this.somTodayEnabled) {
        scripts.push('https://share.connect.somtoday.nl/assets/sharemanual.js')
      }
      scriptjs(scripts, 'share')

      scriptjs.ready('share', () => {
        if (this.msTeamsEnabled) {
          shareToMicrosoftTeams.renderButtons({elements: [this.$refs.msteams]})
        }
        if (this.googleClassroomEnabled) {
          gapi.sharetoclassroom.render(
            this.$refs.googleclassroom,
            {
              size: 32,
              theme: "classic",
              url: this.shareLink,
              title: this.shareDescription,
              onsharestart: () => {
                this.close()
              }
            }
          )
        }
      })
    },

    methods: {
      shareSomToday() {
        if (this.somTodayEnabled) {
          const uri = somtodayShareComponent.getShareUrl(this.shareLink, this.somTodayEnvironment)
          window.open(uri, 'somtoday-share-popup', 'width=700,height=600')
        }
        this.close()
      },

      clickIcon () {
        this.$emit('clickIcon')
      },

      close () {
        this.$emit('close')
      }
    }
  }
</script>
