'use strict'

import { createPopper } from '@popperjs/core'

export class ContextMenuPopper {
  constructor (attachTo) {
    this.attachTo = attachTo
    this._popperInstance = null
  }

  createPopper (trigger, menu) {
    this._popperInstance = createPopper(
      trigger,
      menu,
      {
        placement: 'right-start',
        modifiers: [
          {
            name: 'computeStyles',
            options: { gpuAcceleration: false }
          },
          {
            name: 'offset',
            options: { offset: this.getOffset()  }
          },
          {
            name: 'preventOverflow',
            options: { mainAxis: false }
          }
        ]
      }
    )
  }

  getOffset () {
    if ('right' === this.attachTo) {
      return [0, 0]
    }
    if ('bottom' === this.attachTo) {
      return [24, -24]
    }
    return [-8, -24]
  }

  destroy () {
    if (this._popperInstance === null) {
      return
    }
    this._popperInstance.destroy()
    this._popperInstance = null
  }
}
